var exports = {};

exports = function (method, arg, inverted) {
  const msgList = inverted ? negativeMessages : positiveMessages;
  return msgList[method] && msgList[method](arg);
};

const positiveMessages = {
  min: num => `The string should have a minimum length of ${num} character${pluralify(num)}`,
  max: num => `The string should have a maximum length of ${num} character${pluralify(num)}`,
  letters: (num = 1) => `The string should have a minimum of ${num} letter${pluralify(num)}`,
  digits: (num = 1) => `The string should have a minimum of ${num} digit${pluralify(num)}`,
  uppercase: (num = 1) => `The string should have a minimum of ${num} uppercase letter${pluralify(num)}`,
  lowercase: (num = 1) => `The string should have a minimum of ${num} lowercase letter${pluralify(num)}`,
  symbols: (num = 1) => `The string should have a minimum of ${num} symbol${pluralify(num)}`,
  spaces: (num = 1) => `The string should have a minimum of ${num} space${pluralify(num)}`,
  oneOf: list => `The string should be ${list.length > 1 ? `one of ${list.slice(0, -1).join(", ")} and ` : ""}${list[list.length - 1]}`,
  has: pattern => `The string should have pattern '${pattern}'`,
  not: pattern => `The string should not have pattern '${pattern}'`,
  usingPlugin: fn => `The string should not violate ${fn.name || "plugin"}`
};
const negativeMessages = {
  min: num => `The string should have a maximum length of ${num} character${pluralify(num)}`,
  max: num => `The string should have a minimum length of ${num} character${pluralify(num)}`,
  letters: (num = 0) => `The string should ${num === 0 ? "not have" : `have a maximum of ${num}`} letter${pluralify(num)}`,
  digits: (num = 0) => `The string should ${num === 0 ? "not have" : `have a maximum of ${num}`} digit${pluralify(num)}`,
  uppercase: (num = 0) => `The string should ${num === 0 ? "not have" : `have a maximum of ${num}`} uppercase letter${pluralify(num)}`,
  lowercase: (num = 0) => `The string should ${num === 0 ? "not have" : `have a maximum of ${num}`} lowercase letter${pluralify(num)}`,
  symbols: (num = 0) => `The string should ${num === 0 ? "not have" : `have a maximum of ${num}`} symbol${pluralify(num)}`,
  spaces: (num = 0) => `The string should ${num === 0 ? "not have" : `have a maximum of ${num}`} space${pluralify(num)}`,
  oneOf: list => `The string should not be ${list.length > 1 ? `one of ${list.slice(0, -1).join(", ")} and ` : ""}${list[list.length - 1]}`,
  has: pattern => `The string should not have pattern '${pattern}'`,
  not: pattern => `The string should have pattern '${pattern}'`,
  usingPlugin: fn => `The string should violate ${fn.name || "plugin"}`
};

function pluralify(num) {
  return num === 1 ? "" : "s";
}

export default exports;