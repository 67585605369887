var exports = {};
exports = {
  error: {
    length: "Length should be a valid positive number",
    password: "Password should be a valid string",
    invalidPlugin: "Plugin should be a valid function"
  },
  regex: {
    digits: "(\\d.*)",
    letters: "([a-zA-Z].*)",
    symbols: "([`~\\!@#\\$%\\^\\&\\*\\(\\)\\-_\\=\\+\\[\\{\\}\\]\\\\|;:\\'\",<.>\\/\\?\u20AC\xA3\xA5\u20B9\xA7\xB1].*)",
    spaces: "([\\s].*)"
  }
};
export default exports;